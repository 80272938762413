.section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  font-family:'Roboto', sans-serif;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 45vw;
  background: transparent;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: scale 5s infinite; 
}

.inner-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  height: 100%; 
  background: transparent;
  border: 4px solid white;
  border-radius: 50%;
}

.home__typography {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80%;
}

.home__heading {
  font-size: 4vw;
}

.section__lead {
  color: var(--primary-color);
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Roboto Slab;
  font-size: 2vw;
  text-align: center;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.08em;
}

@media (max-width: 1344px) {
  .section__lead {
    font-size: 1.5vw;
  }

  .home__heading {
    font-size: 5vw;
    padding: 1rem 0;
  }
}

@media (max-width: 768px) {
  .home__heading {
    font-size: 3.5vw;
  }

  .section__lead {
    font-size: 1.5vw;
  }
}

@media (max-width: 600px) {
  .circle,
  .inner-circle {
    width: 90vw;
    height: 90vw;
  }
  
  .home__heading {
    font-size: 7vw;
  }

  .section__lead {
    font-size: 3vw;
  }
}

@media (max-width: 480px) {
  .home__heading {
    font-size: 8vw;
  }

  .section__lead {
    font-size: 3.5vw;
  }
}

@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}