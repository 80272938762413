.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
}

h1 {
    text-align: center;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    color: var(--primary-color);
}

@media (max-width: 650px) {
    .contact-form {
        width: 70%;
    }
}

