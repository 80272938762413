nav {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 200px;
}

.nav-links {
    list-style-type: none;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: var(--primary-color);
    font-weight: 500;
}

.nav-links li {
    display: inline;
    margin: 0 1em;
}

.nav-links a {
  color: white;
  font-weight: 550;
  text-decoration: none;
  font-size:  1.1rem;
}
.nav-links a .icon {
    padding: 0 0.3rem;
}



/* This media query will apply when the screen size is 768px or less */
@media (max-width: 768px) {
    nav {
        flex-direction: column;
    }

    .logo {
        height: 140px;
    }

    .nav-links {
        height: auto;
        flex-direction: column;
    }

    .nav-links li {
        margin: 1em 0;
    }
}

/* This media query will apply when the screen size is 480px or less */
@media (max-width: 480px) {
    nav {
        width: 95%;
    }

    .logo {
        height: 120px;
    }

    .nav-links li {
        margin: 0.5em 0;
    }

    .nav-links a {
        font-size: 1rem;
    }
}