.submit-button {
    width: 100%;
    padding: 0.5em;
    margin: 1em 0;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    font-size: 1.2rem;
    font-family: Roboto;
    font-weight: 500;
}

.submit-button:focus {
    outline: none;
}
