.services {
    width: 100%;
    margin-top: 3rem;
}

.service-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 0 auto;
    width: 80%;
  }
  .service-section div {
    width: 50%;
  }
  .service-section div .heading {
    color: #FFF;
    font-family: Roboto;
    font-size: 75px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 1rem 0;
  }
  
  .service-section p {
    width: 100%;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Roboto Slab;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    text-align: justify;
    color: var(--primary-color);
  }
 
  
  .service-section img {
    width: 40%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .service-section.image-right {
    flex-direction: row-reverse;
  }
  

@media (max-width: 1344px) {
  .service-section div .heading {
    font-size: 40px;
    font-family: 'Public Sans', sans-serif;
  }

  .service-section p {
    font-size: 20px;
    font-family: Roboto;
  }
}

  
@media (max-width: 768px) {
  .service-section {
    flex-direction: column;
    text-align: center;
  }

  .service-section div,
  .service-section img {
    width: 100%;
  }
  
  .service-section.image-below {
    flex-direction: column-reverse;
  }

  .service-section div .heading {
    font-size: 45px;
  }

  .service-section p {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .service-section {
    width: 95%;
  }

  .service-section div .heading {
    font-size: 30px;
    text-align: center;
  }

  .service-section p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 1rem;
  }
}