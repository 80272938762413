/* Input.css */
.input-field {
    width: 100%;
    padding: 1rem;
    margin: 0.5em 0;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
}

.input-field:focus {
    outline: none;
}


/* Textarea.css */
.textarea-field {
    width: 100%;
    padding: 1rem;
    margin: 0.5em 0;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Roboto;
}

.textarea-field:focus {
    outline: none;
}