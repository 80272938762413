@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Public+Sans&display=swap');
@import url(./css/services.css);
@import url(./css/input.css);
@import url(./css/buttons.css);
@import url(./css/form.css);
@import url(./css/navbar.css);
@import url(./css/home.css);

:root {
  --primary-color: #f9ad00;
  --background-color: #22202b;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--background-color);
    color: white;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
}


.divider {
  width: 100%;
  position: relative;
  background-color: var(--background-color);
}

@media screen and (max-width: 700px) {
  .hide-on-small {
      display: none;
  }
}